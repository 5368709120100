import React, { useState } from "react";
import classes from "./Projet.module.css";
// Components
import Articles from "./Articles/Articles";
import UploadDocument from "./UploadDocument/UploadDocument";

const Projet = ({ width, claims, clicked }) => {
  const [pdfs, setPdfs] = useState();
  return (
    <div className={classes.Projet}>
      <div className={classes.DescriptionSection}>
        <div className={classes.DescriptionSvg}>
          <img
            style={{ width: "100%" }}
            alt="hand"
            src={require(`../../../assets/homePage/concertation.svg`)}
          />
        </div>
        <div className={classes.BackgroundShape}></div>
        <div className={classes.Description}>
          <p>
            Pour répondre aux demandes des locataires, assurer la qualité de vos
            logements et répondre aux obligations liées aux règles
            environnementales, des{" "}
            <strong>travaux de rénovation de vos logements</strong> vont être
            engagés dans la résidence. A cet égard, une attention particulière
            sera faite par l’entreprise générale pour protéger votre logement
            lors des interventions de rénovation. Pour les plus fragiles des
            résidents un accompagnement spécifique est prévu afin d’offrir des
            offres de <strong>relogement temporaires</strong>. Enfin, pour
            celles et ceux qui le souhaitent, des logements de courtoisie seront
            mis à disposition des locataires dans la résidence.
          </p>
          <p>
            Les travaux de rénovation dans les logements font suite aux travaux
            déjà réalisés dans les <strong>parties communes</strong>. A savoir:
            L’amélioration de la signalétique et de l’éclairage, la création
            d’un interphone, le remplacement des portes d’accès aux caves et des
            locaux techniques et enfin, le désamiantage et le déplombage.
          </p>
          <p>
            Sur cette plateforme, vous pourrez vous informer sur les différents
            travaux qui impacteront directement votre logement ainsi que leur
            durée, recevoir les dernières actualités concernant le projet de
            rénovation mais aussi poser des questions sur les travaux grâce au
            <strong> cahier de réclamations</strong> en ligne situé dans la
            section “accéder aux travaux”.
          </p>
          <div className={classes.ActionButton}>
            <p onClick={clicked}>Accéder aux travaux {">"}</p>
            {/* <div className={classes.ActionButtonSvg}>
              <img
                style={{ width: "100%" }}
                alt="hand"
                src={require(`../../../assets/homePage/main.svg`)}
              />
            </div> */}
          </div>
        </div>
      </div>
      <div className={classes.Articles}>
        <div className={classes.ArticlesHeader}>
          <h4 style={{ marginBottom: "1.5rem" }}>Actualités</h4>
          {claims && claims.admin && (
            <UploadDocument setPdfs={setPdfs} claims={claims} />
          )}
        </div>
        <Articles setPdfs={setPdfs} pdfs={pdfs} claims={claims} />
      </div>
    </div>
  );
};

export default Projet;
