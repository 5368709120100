import React from "react";

export const travauxCardsData = [
  {
    id: "i1",
    type: "newLayout",
    titleStrong: "Le phasage",
    titleWeak: "des travaux",
    description:
      "Le déroulement des travaux est découpé en 6 tranches successives correspondantes à chaque cage d’escalier. Pour savoir dans quelle phase se situe votre logement, suivez-le guide en cliquant sur explorer.",
    thumbnail: "i1-illustration-Base.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Le déroulement des travaux est découpé en 6 tranches successives correspondantes à chaque cage d’escalier. Pour savoir dans quelle phase se situe votre logement, suivez-le guide en cliquant sur explorer.",
        title: "Le phasage des travaux",
        video: {
          url: "https://youtu.be/4Ud-CYTCYII",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["a"],
  },
  {
    id: "i2",
    type: "newLayout",
    titleStrong: "Les travaux dans",
    titleWeak: "la cours de votre immeuble",
    description:
      "Pour répondre à une demande de plus en plus forte des locataires et améliorer votre confort, un abri vélo et des espaces de propreté pour accueillir les poubelles sont mis en place dans la cour centrale. Cette dernière bénéficie par ailleurs d’un petit coup de neuf, pour plus d’info suivez-le guide en cliquant sur explorer.",
    thumbnail: "i2-illustration-Base.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Pour répondre à une demande de plus en plus forte des locataires et améliorer votre confort, un abri vélo et des espaces de propreté pour accueillir les poubelles sont mis en place dans la cour centrale. Cette dernière bénéficie par ailleurs d’un petit coup de neuf, pour plus d’info suivez-le guide en cliquant sur explorer.",
        title: "Les travaux dans la cours de votre immeuble",
        video: {
          url: "https://youtu.be/B7b3Y9kQDv4",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["a"],
  },
  {
    id: "i3",
    type: "newLayout",
    titleStrong: "Les travaux sur",
    titleWeak: "la façade de votre immeuble",
    description:
      "Les façades d’immeuble sont en grande partie responsables des déperditions de chaleur de votre logement. Cela affecte votre consommation et votre confort.",
    thumbnail: "briques.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Les façades d’immeuble sont en grande partie responsables des déperditions de chaleur de votre logement. Cela affecte votre consommation et votre confort.",
        title: "Les travaux sur la façade de votre immeuble",
        video: {
          url: "https://youtu.be/Q7r3E71zg3I",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["a"],
  },
  {
    id: "i4",
    type: "newLayout",
    titleStrong: "Les toitures",
    titleWeak: "et planchers de votre immeuble",
    description:
      "L’isolation des toitures des planchers des caves de votre immeuble permet d’améliorer ses performances énergétiques et par conséquent le confort et la consommation des logements situés au-dessus ainsi que dans les parties communes.",
    thumbnail: "Isolation-combles.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "pins",
        title: "Les toitures et planchers de votre immeuble",
        description: (
          <>
            L’isolation des toitures de votre immeuble permet d’améliorer ses
            performances énergétiques et par conséquent le confort et la
            consommation des logements situés en dessous.
          </>
        ),
        imageUrl: "Isolation-combles.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-1",
            description: "Charpente de la toiture.",
            top: "26%",
            left: "50%",
            opened: false,
          },
          {
            pinId: "pin-2",
            description: "Isolant.",
            top: "60%",
            left: "20%",
            opened: false,
          },
          {
            pinId: "pin-3",
            description: "Couverture de l’isolant.",
            top: "55%",
            left: "38%",
            opened: false,
          },
          {
            pinId: "pin-4",
            description: (
              <>
                L’isolation des toitures de votre immeuble permet d’améliorer
                ses performances énergétiques et par conséquent le confort et la
                consommation des logements situés en dessous.
              </>
            ),
            top: "80%",
            left: "40%",
            opened: false,
          },
        ],
      },
      {
        contentId: "content-2",
        type: "pins",
        title: "Les toitures et planchers de votre immeuble",
        description: (
          <>
            L’isolation des toitures des planchers des caves de votre immeuble
            permet d’améliorer ses performances énergétiques et par conséquent
            le confort et la consommation des logements situés au-dessus ainsi
            que dans les parties communes.
          </>
        ),
        imageUrl: "Isolation-plafond-cave.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-1",
            description: "Charpente de la toiture.",
            top: "43%",
            left: "33%",
            opened: false,
          },
          {
            pinId: "pin-2",
            description: "Isolant.",
            top: "52%",
            left: "50%",
            opened: false,
          },
          {
            pinId: "pin-3",
            description: "Couverture de l’isolant.",
            top: "30%",
            left: "50%",
            opened: false,
          },
          {
            pinId: "pin-4",
            description: (
              <>
                L’isolation des toitures des planchers des caves de votre
                immeuble permet d’améliorer ses performances énergétiques et par
                conséquent le confort et la consommation des logements situés
                au-dessus ainsi que dans les parties communes.
              </>
            ),
            top: "70%",
            left: "40%",
            opened: false,
          },
        ],
      },
    ],
    tags: "Illustration",
    groupe: ["a"],
  },
  // {
  //   id: "i5",
  //   type: "newLayout",
  //   titleStrong: "Les travaux dans",
  //   titleWeak: "les parties communes",
  //   description: "Description (en attente)",
  //   thumbnail: "illustrationAttente.png",
  //   submitted: false,
  //   content: [
  //     {
  //       contentId: "content-1",
  //       type: "pins",
  //       title: "Les travaux dans les parties communes",
  //       description: "",
  //       imageUrl: "illustrationAttente.png",
  //       openedPinsCount: 0,
  //       pins: [],
  //     },
  //   ],
  //   tags: "Illustration",
  //   groupe: ["a"],
  // },
  {
    id: "i6",
    type: "newLayout",
    titleStrong: "Les travaux",
    titleWeak: "dans votre logement",
    description:
      "De votre porte d’entrée au réseau de gaz et d’électricité, cliquez sur explorer pour vous informer de tous les travaux qui auront lieu dans votre logement et leur durée d’intervention.",
    thumbnail: "i6-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "De votre porte d’entrée au réseau de gaz et d’électricité, cliquez sur explorer pour vous informer de tous les travaux qui auront lieu dans votre logement et leur durée d’intervention.",
        title: "Les travaux dans votre logement",
        video: {
          url: "https://youtu.be/SzPJco8_nDw",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["a"],
  },
  {
    id: "i7a",
    type: "newLayout",
    titleStrong: "La création d’une VMC",
    titleWeak: "dans votre logement",
    description:
      "La mise en place d’une VMC dans votre logement fait partie des obligations de travaux en raison des exigences en termes de performance énergétique et d’amélioration de confort. Si vous souhaitez en savoir plus cliquez sur explorer.",
    thumbnail: "i7-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "La mise en place d’une VMC dans votre logement fait partie des obligations de travaux en raison des exigences en termes de performance énergétique et d’amélioration de confort. Si vous souhaitez en savoir plus cliquez sur explorer.",
        title: "La création d’une VMC dans votre logement",
        video: {
          url: "https://youtu.be/wmFSUoLOR9A",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["a"],
  },
  {
    id: "i7b",
    type: "newLayout",
    titleStrong: "La création d’une VMC",
    titleWeak: "dans votre logement",
    description:
      "La mise en place d’une VMC dans votre logement fait partie des obligations de travaux en raison des exigences en termes de performance énergétique et d’amélioration de confort. Si vous souhaitez en savoir plus cliquez sur explorer.",
    thumbnail: "i7-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "La mise en place d’une VMC dans votre logement fait partie des obligations de travaux en raison des exigences en termes de performance énergétique et d’amélioration de confort. Si vous souhaitez en savoir plus cliquez sur explorer.",
        title: "La création d’une VMC dans votre logement",
        video: {
          url: "https://youtu.be/G2F6FjzxV70",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["a"],
  },
  {
    id: "i8",
    type: "newLayout",
    titleStrong: "L'amélioration de la",
    titleWeak: "performance énergétique",
    description:
      "Ces travaux consistent à améliorer et uniformiser tous les équipements qui transportent l’énergie de votre immeuble jusque dans votre logement. Le but est que ces équipements soient plus performants et ainsi plus économes. Cela se traduit pour vous par un gain en termes de confort et des économies sur vos factures.",
    thumbnail: "i8-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Ces travaux consistent à améliorer et uniformiser tous les équipements qui transportent l’énergie de votre immeuble jusque dans votre logement. Le but est que ces équipements soient plus performants et ainsi plus économes. Cela se traduit pour vous par un gain en termes de confort et des économies sur vos factures.",
        title: "L'amélioration de la performance énergétique",
        video: {
          url: "https://youtu.be/Ilxv6zrm5tY",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["a"],
  },
  {
    id: "i9",
    type: "newLayout",
    titleStrong: "L’accompagnement",
    titleWeak: "des locataires",
    description:
      "Afin de vous accompagner, Élogie-Siemp a fait appel aux compétences d’une Maîtrise d’Oeuvre Urbaine et Sociale (MOUS), l’association SOLIHA. Les relogements provisoires seront validés et réalisés avec l'aide la MOUS (signature de bail précaire, aide au déménagement des affaires personnelles...). Pour plus d’informations, suivez le guide en cliquant sur explorer ci-dessous.",
    thumbnail: "i9-illustration-Base.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Afin de vous accompagner, Élogie-Siemp a fait appel aux compétences d’une Maîtrise d’Oeuvre Urbaine et Sociale (MOUS), l’association SOLIHA. Les relogements provisoires seront validés et réalisés avec l'aide la MOUS (signature de bail précaire, aide au déménagement des affaires personnelles...). Pour plus d’informations, suivez le guide en cliquant sur explorer ci-dessous.",
        title: "L’accompagnement des locataires",
        video: {
          url: "https://youtu.be/ClNPMJC8y2s",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["a"],
  },
];
