import React from "react";

export const CalendarData = [
  {
    id: 0,
    emoji: "📰",
    title: "Étape 1",
    date: "",
    duration: "",
    plan: "",
    substeps: [
      {
        id: 0,
        emoji: "📰",
        title: "",
        date: "Mars 2021",
        duration: "",
        description: "Information avec les représentants des locataires.",
      },
    ],
  },
  {
    id: 1,
    emoji: "💨",
    title: "Étape 2",
    date: "",
    duration: "",
    plan: "",
    substeps: [
      {
        id: 0,
        emoji: "💨",
        title: "",
        date: "23 juin 2021",
        duration: "",
        description: "Groupe de travail ventilation (VMC)",
      },
      {
        id: 2,
        emoji: "🔎",
        title: "",
        date: "8 juin 2021",
        duration: "",
        description: "Visite d’une opération de réhabilitation.",
      },
    ],
  },
  {
    id: 2,
    emoji: "👷",
    title: "Étape 3",
    date: "",
    duration: "",
    plan: "",
    substeps: [
      {
        id: 0,
        emoji: "📰",
        title: "",
        date: "Septembre 2021 (environ 6 semaines)",
        duration: "",
        description: "Nouvelle réunion d’information.",
      },
      {
        id: 2,
        emoji: "👷",
        title: "",
        date: "4éme trimestre 2021",
        duration: "",
        description: "Re-démarrage des travaux.",
      },
    ],
  },
  {
    id: 3,
    emoji: "🏢",
    title: "Étape 4",
    date: "",
    duration: "",
    plan: "",
    substeps: [
      {
        id: 0,
        emoji: "👷",
        title: "",
        date: "Du 3eme trimestre 2021 au 4eme trimestre 2021",
        duration: "",
        description:
          "Travaux dans l’immeuble du 12 rue Mounet Sully. (Phase 4)",
      },
      {
        id: 2,
        emoji: "👷",
        title: "",
        date: "Du  4eme trimestre 2021 au 1er trimestre 2022",
        duration: "",
        description:
          "Travaux dans l’immeuble du 14 rue Mounet Sully. (Phase 5)",
      },
      {
        id: 2,
        emoji: "👷",
        title: "",
        date: "Du  1er trimestre 2022 au 2eme trimestre 2022",
        duration: "",
        description:
          "Travaux dans l’immeuble du 16 rue Mounet Sully. (Phase 6)",
      },
    ],
  },
  {
    id: 3,
    emoji: "📅",
    title: "Étape 5",
    date: "",
    duration: "",
    plan: "",
    substeps: [
      {
        id: 0,
        emoji: "📅",
        title: "",
        date: "Novembre 2023",
        duration: "",
        description: "Fin prévisionnelle des travaux",
      },
    ],
  },
];
